var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        id: "modalRefundConfirm",
        size: "lg",
        title: _vm.$t("payments.refund.modalTitle"),
        "cancel-title": _vm.$t("payments.buttons.close"),
      },
      on: { hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c(
        "b-container",
        [
          _c(
            "b-form",
            { attrs: { validate: "" } },
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-12 col-sm-12",
                      attrs: {
                        label: _vm.$t("payments.labels.paymentDescription"),
                        "label-for": "transaction-description",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "transaction-description",
                          disabled:
                            _vm.block_ui ||
                            _vm.block_ui_update ||
                            _vm.block_ui_canceled,
                          type: "text",
                        },
                        model: {
                          value: _vm.modalData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.modalData, "description", $$v)
                          },
                          expression: "modalData.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-12 col-sm-12",
                      attrs: {
                        label: _vm.$t("payments.labels.paymentMethod"),
                        "label-for": "payment-method",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "payment-method",
                          disabled:
                            _vm.block_ui ||
                            _vm.block_ui_update ||
                            _vm.block_ui_canceled,
                          type: "text",
                        },
                        model: {
                          value: _vm.methodPaymentText,
                          callback: function ($$v) {
                            _vm.methodPaymentText = $$v
                          },
                          expression: "methodPaymentText",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c("mask-input", {
                    attrs: {
                      "mask-label": _vm.$t("payments.labels.value"),
                      prepend: _vm.$t("payments.labels.currency"),
                      "parent-class": "col-md-4 col-sm-12",
                      disabled:
                        _vm.block_ui ||
                        _vm.block_ui_update ||
                        _vm.block_ui_canceled,
                      mask: ["####,##", "###,##", "##,##", "#,##"],
                    },
                    model: {
                      value: _vm.modalData.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "amount", $$v)
                      },
                      expression: "modalData.amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-12 col-sm-12",
                      attrs: {
                        label: _vm.$t("payments.labels.paymentComments"),
                        "label-for": "transaction-internal-comments",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "transaction-internal-comments",
                          type: "text",
                          rows: 1,
                          "max-rows": 4,
                          state: _vm.erroComments,
                        },
                        model: {
                          value: _vm.refundInternalComments,
                          callback: function ($$v) {
                            _vm.refundInternalComments = $$v
                          },
                          expression: "refundInternalComments",
                        },
                      }),
                      _c("b-form-invalid-feedback", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("payments.labels.mandatoryField")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "float-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "ml-2",
              attrs: { "data-test": "modal-transaction__btn-save" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closeRefund")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("payments.refund.cancelRefund")) + " ")]
          ),
          _vm.modalData.status === "PAID"
            ? _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    variant: "success",
                    "data-test": "modal-transaction__btn-save",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.manualRefund()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("payments.refund.confirmRefund")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }