<template>
  <b-modal
    lazy
    no-fade
    no-enforce-focus
    hide-footer
    id="modalRefundConfirm"
    size="lg"
    :title="$t('payments.refund.modalTitle')"
    :cancel-title="$t('payments.buttons.close')"
    v-model="cpHandlerModal"
    @hidden="handleClose">
    <b-container>
      <b-form validate>
        <b-form-row>
          <b-form-group :label="$t('payments.labels.paymentDescription')" label-for="transaction-description" class="col-md-12 col-sm-12">
            <b-form-input id="transaction-description" :disabled="block_ui || block_ui_update || block_ui_canceled" type="text" v-model="modalData.description" />
          </b-form-group>
        </b-form-row>
        <b-form-row>
           <b-form-group :label="$t('payments.labels.paymentMethod')" label-for="payment-method" class="col-md-12 col-sm-12">
            <b-form-input id="payment-method" :disabled="block_ui || block_ui_update || block_ui_canceled" type="text" v-model="methodPaymentText" />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <mask-input 
            :mask-label="$t('payments.labels.value')"
            :prepend="$t('payments.labels.currency')"
            parent-class="col-md-4 col-sm-12"
            :disabled="block_ui || block_ui_update || block_ui_canceled"
            :mask="['####,##', '###,##', '##,##', '#,##']"
            v-model="modalData.amount"></mask-input>
        </b-form-row>
        <b-form-row>
          <b-form-group :label="$t('payments.labels.paymentComments')" label-for="transaction-internal-comments" class="col-md-12 col-sm-12">
            <b-form-textarea id="transaction-internal-comments"
              type="text"
              v-model="refundInternalComments"
              :rows="1"
              :max-rows="4"
              :state="erroComments" />
              <b-form-invalid-feedback v-html="$t('payments.labels.mandatoryField')" />
          </b-form-group>
      </b-form-row>
      </b-form>
    </b-container>

    <div class="float-right">
      <b-button
        class="ml-2"
        data-test="modal-transaction__btn-save"
        @click="$emit('closeRefund')"
      >{{ $t('payments.refund.cancelRefund') }}
      </b-button>

      <b-button
        v-if="modalData.status === 'PAID'"
        @click="manualRefund()"
        variant="success"
        class="ml-2"
        data-test="modal-transaction__btn-save"
      >{{ $t('payments.refund.confirmRefund') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import MANUAL_REFUND from '@graphql/payment/mutations/refund.gql'
import { mapGetters } from 'vuex'
export default {
  name: "refundConfirm",
  props: {
    modalData: {
      type: Object,
      required: true
    },
    methodsPayment: {
      type: String,
      required: true
    },
    handlerModal: {
      type: Boolean,
    },
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  data () {
    return {
      block_ui: true,
      block_ui_update: true,
      block_ui_canceled: true,
      refundInternalComments: '',
      erroComments: null,
    }
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
    methodPaymentText() {
      return this.methodsPayment
    }
  },
  methods: {
    handleClose() {
      this.$emit('change', false);
    },
    manualRefund() {
      if (!this.refundInternalComments) {
        this.erroComments = false;
        return;
      };
      this.erroComments = true;
      this.$apollo.mutate({
        mutation: MANUAL_REFUND,
        variables: {
          id: this.modalData.id,
          internal_comments: `[ ${this.attributes.email}
                @${this.$moment().format('DD/MM/YYYY HH:mm')} ] -- ${this.refundInternalComments}\n\n`,
        }
      })
        .then(() => {
          this.$emit('succesRefund')
          this.$emit('closeRefund')
        })
        .catch((error) => {
          this.$emit('errorRefund')
          this.$emit('finishRefund')
        })
    }
  }
}
</script>